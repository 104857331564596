@font-face {
font-family: 'gilroyoffice';
src: url(/_next/static/media/51a0ed54ad5e5fde-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'gilroyoffice';
src: url(/_next/static/media/bb6fed8848791e64-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: 'gilroyoffice';
src: url(/_next/static/media/bd5821b525abd626-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: 'gilroyoffice Fallback';src: local("Arial");ascent-override: 90.94%;descent-override: 24.61%;line-gap-override: 0.00%;size-adjust: 105.56%
}.__className_6ff1ab {font-family: 'gilroyoffice', 'gilroyoffice Fallback'
}

